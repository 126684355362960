

















































import Vue from 'vue'
import Component from 'vue-class-component'
import { request } from '@/edshed-common/api'

@Component
export default class XeroSettings extends Vue {
  private consentUrl: string = ''
  private tenants: any[] = []
  private isLoading: boolean = false

  public mounted () {
    this.getAuthLink()
    this.getTenants()
  }

  getAuthLink () {
    request('GET', 'superuser/xero/consent', null)
      .then((response) => {
        const data = response.data
        console.log(data)
        this.consentUrl = data.url
      })
      .catch((error) => {
        console.log(error)

        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }

        /// this.response = 'Details incorrect'
      })
  }

  getTenants () {
    this.isLoading = true
    request('GET', 'superuser/xero/tenants', null)
      .then((response) => {
        this.isLoading = false
        const data = response.data
        console.log(data)
        this.tenants = data.tenants
      })
      .catch((error) => {
        this.isLoading = false

        console.log(error)

        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }

        /// this.response = 'Details incorrect'
      })
  }

  addConnectionClicked () {
    console.log('click!')
    window.open(this.consentUrl, '_blank')
  }

  deleteConnection (connectionId) {
    this.isLoading = true

    request('DELETE', 'superuser/xero/tenants/' + connectionId, null)
      .then((response) => {
        this.getTenants()
        this.isLoading = false
      })
      .catch((error) => {
        this.isLoading = false
        console.log(error)

        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }

        /// this.response = 'Details incorrect'
      })
  }
}
